@import "mixins";

.hidden { display: none !important; }

.align-center { text-align: center; }
.align-right { text-align: right; }

.f-left { float: left; }
.f-right { float: right; }

.clear:after {
  display: block;
  content: " ";
  clear: both;
  height: 0;
}

.ie-only { display: none; }

@include breakpoint(phone) {
  .desktop-only, .tablet-only, .not-mobile { display: none !important; }
  .mobile-only { display: inherit !important; }
}

@include breakpoint(tablet) {
  .mobile-only, .desktop-only, .not-tablet { display: none !important; }
  .tablet-only { display: inherit !important; }
}

@include breakpoint(desktop) {
  .mobile-only, .tablet-only, .not-desktop { display: none !important; }
  .desktop-only { display: inherit !important; }
}
