@mixin breakpoint($point) {
  @if $point == phone {
    @media (max-width: 767px) { @content; }
  }

  @else if $point == tablet {
    @media (min-width: 768px) { @content; }
  }

  @else if $point == desktop {
    @media (min-width: 992px) { @content; }
  }
}