// -----------------------------------------------------------------------------
// Reset
// -----------------------------------------------------------------------------

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// -----------------------------------------------------------------------------
// Body
// -----------------------------------------------------------------------------

html, body {
  background: #fff;
  font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  color: #000;
}

// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

label, p, li { font-size: 1.4rem; }

p {
  margin: 0 0 2rem;
  line-height: 2rem;

  &:last-child { margin-bottom: 0; }
}

a {
  color: #ea2040;
  text-decoration: underline;

  &:hover { color: #000; }
}

mark {
  padding: 4px 0;
  background: #fdffb6;
  color: #222;
}

// -----------------------------------------------------------------------------
// Flash messages
// -----------------------------------------------------------------------------

ul.error {
  padding: 20px 10px;
  background: #FFCDD2;
  list-style: none;
  color: #B71C1C;
}

ul.success {
  padding: 20px 10px;
  background: #E8F5E9;
  list-style: none;
  color: #004D40;
}

ul.warning {
  padding: 20px 10px;
  background: #FFF176;
  list-style: none;
}

ul.success, ul.error {
  li { cursor: default; }
}

// -----------------------------------------------------------------------------
// Forms
// -----------------------------------------------------------------------------

.inline-form-container {
  margin: 6rem 0;
  padding: 3rem 0;
  border-top: solid 3px #000;

  h2 { margin-bottom: 0.5rem; }
}

form {
  dl, dd { position: relative; }

  dl { margin: 10px 0; }
  dd.info { display: none; }

  dd.error {
    margin-top: 1rem;
    text-align: right;
    font-size: 1.4rem;
    color: #B71C1C;
  }

  dl.error input, dl.error textarea, div.checkbox-area.error {
    background: #EF9A9A;
    border-color: #EF5350;
  }
}

label {
  display: inline-block;
  padding: 4px 0;
  font-weight: bold;
}

input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number], input[type=search], dd select, textarea {
  padding: 8px 10px;
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 3px;
  font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
  font-size: 2rem;

  &:focus {
    outline: none;
    border-color: #62cae3;
  }

  &.inline { width: 200px; }

  &.input-small {
    font-size: 1.2rem;
  }
  &.input--date--tiny {
    padding: 2px;
    border: solid 1px #ddd;
    width: 90px;
    text-align: center;
    font-size: 1.4rem;
  }
}

input[type=file] {
  display: block;
  padding: 10px;
  width: 100%;
  background: #eee;
  border: solid 1px #ccc;
}

.filter-search { margin-bottom: 1rem; }
.filter-search--input { border-radius: 0 !important; }

.sidebyside {
  margin-bottom: 20px;

  &:after {
    display: block;
    content: " ";
    clear: both;
    height: 0;
  }

  dl {
    float: left;
    margin: 0;
    width: 50%;

    label { padding-top: 0; }

    &:first-child { padding-right: 10px; }
    &:last-child { padding-left: 10px; }
  }
}

.input--info {
  margin: 2rem 0 0;
  font-style: italic;
}

.input--plaintext {
  padding: 2px 0 !important;
  background: transparent;
  border: none !important;
  cursor: default;
}

button, .btn {
  display: inline-block;
  margin: 5px 0;
  padding: 10px 60px;
  background: #62cae3;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
  font-size: 2rem;

  &:hover, &:focus, &.active {
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }

  &:not(.disabled):hover, &:not(.disabled):focus { background: #5ab7cc; }
}

.btn-destructive, .btn-red {
  background: #EF5350;

  &:not(.disabled) {
    &:hover, &:focus { background: #E57373; }
  }
}

.btn-gray {
  background: #78909C;

  &:not(.disabled) {
    &:hover, &:focus { background: #607D8B; }
  }
}

.btn-show {
  background: none;
  border: none;
  color: #62cae3;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:not(.disabled) {
    &:hover, &:focus {
      background: none;
      color: #5ab7cc;
    }
  }
}

.btn-narrow { padding: 10px 20px; }

.btn-small {
  padding: 6px 20px;
  font-size: 1.4rem;
}

button.btn-link{
  color: #62cae3;
  background: none;
  font-size: 1.4rem;
  padding: 6px 20px;
  margin: 5px 0;

  &:hover, &:focus, &.active {
    outline: none;
    cursor: pointer;
    background: none;
    text-decoration: none;
    text-decoration: underline;
  }
}

.btn-tiny {
  padding: 2px 10px;
  font-size: 1.2rem;
}

.disabled, button:disabled {
  opacity: 0.25;
  cursor: default !important;
  pointer-events: none;

  a, .btn {
    pointer-events: none;
    cursor: default !important;
  }
}

.disabled--reason {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 1px;
  right: 1px;
  transform: translate(0, -50%);
  background: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #B71C1C;
  text-decoration: underline;
}

.checklist { margin: 2rem 0; }

.checklist--item {
  padding: 0.75rem 1.5rem;
  border-bottom: dashed 1px #bbb;

  &:hover { background: #eee; }
  &:last-child { border-bottom: none; }

  label:first-child { min-width: 40%; }
  span{
    font-size: 1.5em;
    color: #62cae3;
    font-weight: bold;
    padding: 4px 0;
  }
}

.checkbox-inline {
  display: inline-block;
  margin-left: 6rem;
}

.form-attach {
  position: relative;
  background: #eee;
  border-left: solid 1px #999;
  border-right: solid 1px #999;
  border-bottom: solid 1px #999;

  .form-attach--meta {
    position: relative;

    > a {
      display: block;
      padding: 1rem;
      font-size: 1.4rem;
    }

    > em {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(0, -50%);
      font-size: 1.1rem;
      color: #666;
    }
  }

  ul {
    margin: 0 !important;
    padding: 0.5rem 0;
    border-top: dashed 1px #999;
    list-style: none;

    li {
      padding: 0.5rem 1rem;
    }
  }
}

.attachment-list {
  input[type="file"] { display: none; }
  input[type="file"]:last-child {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.calendar-trigger {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 2rem;
}

// -----------------------------------------------------------------------------
// Tables
// -----------------------------------------------------------------------------

table {
  font-size: 1.4rem;

  td, th {
    padding: 0.5rem 1rem 0.5rem 0;

    &:last-child { padding-right: 0; }
  }

  th {
    text-align: left;
    font-weight: bold;
  }
}

// -----------------------------------------------------------------------------
// Trix editor
// -----------------------------------------------------------------------------

trix-toolbar .trix-button-row { justify-content: inherit !important; }
trix-toolbar .trix-dialogs .trix-dialog .trix-button-group input[type=button],
trix-toolbar .trix-dialogs .trix-dialog--link .trix-dialog__link-fields input[type=url] { font-size: 1rem !important; }

trix-toolbar .trix-button-group:last-child {
  margin-right: 0 !important; }

trix-toolbar .trix-button-group {
  margin: 0 8px 0 0 !important;
  border: 1px solid #999 !important;
  border-bottom: none !important;
  border-radius: 0 !important;

  .trix-button--icon, input[type=button].icon {
    padding: 0;
    border-radius: 0;
    border-bottom: none !important;
    font-size: 1.5rem
  }

  button.mark {
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 18px !important;
    color: #ed1b3b !important;
  }

  button:hover, button:focus { background: #E3F2FD !important; }
  button.active, button.active:hover, button.active:focus { background: #E3F2FD !important; }
}

trix-toolbar .trix-dialogs {
  .trix-dialog {
    top: 5px !important;
    left: 5px !important;
    border-radius: 2px !important;
    border: solid 1px #999 !important;
  }

  .trix-button-group { border-bottom: 1px solid #999 !important; }
}

trix-editor {
  background: #fff;
  border: 1px solid #999 !important;
  border-radius: 0 !important;
  font-size: 1.4rem;

  h1 {
    margin-bottom: 1rem !important;;
    font-size: 2rem !important;
  }

  mark {
    background: none !important;
    color: #ed1b3b;
  }
}

.trix--limited-options trix-toolbar .block_tools { display: none !important; }

// -----------------------------------------------------------------------------
// DataTables
// -----------------------------------------------------------------------------
table#user_list.dataTable,
table#customer_list.dataTable {
  width: 100% !important;
}

table#user_list.dataTable tbody td,
table#customer_list.dataTable tbody td {
  padding: 15px 10px;
}

table#user_list.dataTable tbody tr:hover,
table#customer_list.dataTable tbody tr:hover {
  background-color: #effbfe;
  cursor: pointer;
}

.dataTables_wrapper div.bottom {
  padding-top: 2rem !important;
}

.dataTables_processing {
  font-size: 1.4rem !important;
}

div.dataTables_filter {
  width: 100%;
}

.dataTables_filter {
  label {
    width: 100%;
  }
  input {
    padding: calc(.5rem + 8px) calc(1.5rem + 10px) !important;
    width: 100%;
    border: solid 1px #ddd;
    border-radius: 3px !important;
    font-family: 'Trebuchet MS', Helvetica, Arial, sans-serif;
    font-size: 2rem;

    &:focus {
      outline: none;
      border-color: #62cae3 !important;
    }
  }
}

.dataTables_info {
  float: none !important;
  display: inline-block;
}

.dataTables_info, .dataTables_paginate {
  font-size: 1.2rem;
}

.dataTables_length  {
  display: inline-block;
  margin-right: 3rem;

  label {
    font-size: 1.2rem;
  }
  select {
    cursor: pointer;
    font-size: 1.2rem;
  }
}


.dataTables_customers {
  color: #62cae3;
}

.dataTables_activeUsers {
  td:nth-child(2) {
    text-transform: lowercase;
    text-align: end;
    font-variant: small-caps;
    color: #786861;
  }
}

.dataTables_inactiveUsers {
  td:nth-child(2) {
    text-transform: lowercase;
    text-align: start;
    font-variant: small-caps;
    color: #df1436;
  }

  td:nth-child(3) {
    text-transform: lowercase;
    text-align: end;
    font-variant: small-caps;
    color: #786861;
  }
}

.dataTable thead th, .dataTable thead td, .dataTable.no-footer {
  border-bottom: none !important;
}

.dataTables_empty {
  cursor: default;
  color: #000;
}

.dataTables_wrapper .dataTables_processing {
  height: 80px !important;
}

// -----------------------------------------------------------------------------
// Content containment
// -----------------------------------------------------------------------------

.container {
  position: relative;
  margin: 0 auto;
}

.content { padding: 20px; }

// -----------------------------------------------------------------------------
// Content structure
// -----------------------------------------------------------------------------

nav ul { list-style: none; }

hr {
  margin: 3rem 0;
  height: 0;
  background: none;
  border: none;
  border-bottom: solid 3px #000;
}


.label{
  display: inline;
  text-transform: lowercase;
  font-variant: small-caps;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1;
  margin-left: 1rem;
}

.label-blue{
  color: #62cae3;
}
.label-yellow{
  color: #FBC02D;
}