@import "utils/common";
@import "utils/formatters";
@import "utils/mixins";
@import "vendor/intlTelInput";

.auth-container {
  position: relative;
  z-index: 2;
  background: #fff;
}

.auth-blocked {
  padding: 20px 20px 30px;
}

@include breakpoint(tablet) {
  .auth-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    max-width: 100%;
    border: solid 1px #3e3e3e;
    border-radius: 3px;
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
  }
}

ul.error {
  border-top: solid 1px #EF5350;
  border-bottom: solid 1px #EF5350;
}

ul.success {
  border-top: solid 1px #4DB6AC;
  border-bottom: solid 1px #4DB6AC;
}

form {
  padding: 20px 20px 30px;
}

input {
  width: 100% !important;
}

button {
  width: 100%;
  background: #22409a;
  white-space: nowrap;

  &:hover, &:focus {
    background: #2b51c4 !important;
  }
}

.sign-method-container {
  padding: 20px 20px 20px;

  .spinner {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
  }

  form {
    padding: 0;
  }

  .button-wrapper {
    padding: 1rem 0;
  }

  .button {
    font-size: 2rem;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #22409a;
  }

  .button:hover {
    background-color: #2b51c4;
  }

  h1 {
    font-size: 3.5em;
    line-height: 4rem;
  }
}


#e-ident {
  border: none;
  padding: 20px 20px 0 20px;
  width: 100%;
  height: 480px;
}

.logotype {
  position: relative;
  padding: 25px 20px 20px;
  background: #3e3e3e;
  text-align: center;

  strong {
    display: block;
    margin-top: 5px;
    text-align: center;
    font-size: 1rem;
    color: #fff;
  }
}

#sms-code-form {
  padding-bottom: 0;
}

#sms-hint {
  margin-bottom: 10px;
}


.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip .tooltiptext {
  font-size: 1.4rem;
  width: 240px;
  background-color: #3e3e3e;
  cursor: pointer;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  top: 100%;
  left: 50%;
  margin-left: -120px; /* Use half of the width (120/2 = 60), to center the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext:hover {
  background-color: #555;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

div#ie-banner {
  background-color: #EF5350;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 1.4rem;

  .banner-content {
    width: 800px;
    margin: 0 auto;
    padding: 10px;
  }
}
.system-status {
  position: relative;
  z-index: 3;
  margin-bottom: 20px;

  .item {
    padding: 1rem 2rem;
    background: #FFF59D;
    border-bottom: solid 1px #eee385;
    text-align: center;
    font-size: 1.4rem;
  }
}




